import { FC, useMemo, useState } from "react";
import { Box, Button, Dialog, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteVehicle } from "../../../../Services/LondunarkerfiAPIService";
import User from "../../../../Models/UserModels/User";
import { useConfirm } from "material-ui-confirm";
import Vehicle from "../../../../Models/CatchRegistrationModels/Vehicle";
import { DataGrid, GridActionsCellItem, GridColDef, GridOverlay } from "@mui/x-data-grid";
import VehicleFormModal from "./components/VehicleFormModal";
import SearchIcon from '@mui/icons-material/Search';
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import { logError } from "../../../../Helpers/LogError";

interface VehicleModalProps {
  open: boolean;
  toggleOpen: () => void;
  refetchVehicles: () => Promise<void>;
  vehicles: Vehicle[];
  showSnackbar: (message: string, severity: string) => void;
  user: User;
}

/**
 * Functional component for vehicle modal.
 * @param {VehicleModalProps} props 
 * @returns {JSX} renders the email modal.
 * 
 * Responsible for rendering modal for CRUD operations on vehicles.
 */

const VehicleModal: FC<VehicleModalProps> = (props: VehicleModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const confirm = useConfirm();

  const handleClose = () => {
    props.toggleOpen();
  };

  const filterData = () => {
    const lowerSearchTerm = searchTerm.toLowerCase();

    return (props.vehicles || []).filter((vehicle) => {
      const comment = vehicle.comment || '';
      return (
        vehicle.vehicleNumber?.toString().toLowerCase().includes(lowerSearchTerm) ||
        vehicle.vehicleRegistrationNumber?.toLowerCase().includes(lowerSearchTerm) ||
        vehicle.vehicleTypeName?.toLowerCase().includes(lowerSearchTerm) ||
        comment.toLowerCase().includes(lowerSearchTerm)
      );
    });
  };

  const columns = useMemo<GridColDef<Vehicle>[]>(() => [
    { field: 'vehicleNumber', headerName: 'Bílnúmer', type: 'number', headerAlign: 'left', flex: 1 },
    { field: 'vehicleRegistrationNumber', headerName: 'Fastnúmer', type: 'singleSelect', headerAlign: 'left', flex: 1 },
    { field: 'vehicleWeight', headerName: 'Eiginþyngd', type: 'number', headerAlign: 'left', flex: 1 },
    { field: 'vehicleTare', headerName: 'Tara', type: 'number', headerAlign: 'left', flex: 1 },
    { field: 'vehicleTypeName', headerName: 'Tegund', type: 'string', headerAlign: 'left', flex: 1 },
    { field: 'comment', headerName: 'Athugasemd', headerAlign: 'left', flex: 1.5 },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        if (props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)) return [];
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={handleDelete(params.id as number)}
          />
        ];
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.vehicles]
  );

  const NoRowsOverlay = () => {
    return (
      <GridOverlay>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"

        >
          <SearchIcon fontSize="large" color="action" />
          <Typography variant="h6" color="textSecondary">
            Engin farartæki fundust með gefnum skilyrðum.
          </Typography>
        </Box>
      </GridOverlay>
    );
  };

  const handleDelete = (vehicleId: number) => async () => {
    setLoading(true);
    const vehicle = props.vehicles.find((v) => v.id === vehicleId);
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða ökutæki: ${vehicle?.vehicleNumber}.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delVehicle(vehicleId as number))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    setLoading(false);
  };

  const delVehicle = async (vehicleId: number) => {
    try {
      await deleteVehicle(vehicleId);
      props.refetchVehicles();
      props.showSnackbar("Ökutæki eytt.", "success");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const toggleOpenVehicleForm = () => {
    setOpenForm(!openForm);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (params: any) => {
    selectedVehicle === params.row ? setSelectedVehicle(null) : setSelectedVehicle(params.row);
    toggleOpenVehicleForm();
  };

  const resetVehicle = () => {
    setSelectedVehicle(null);
  };

  const handleNewClick = () => {
    resetVehicle();
    toggleOpenVehicleForm();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={'md'}
    >
      <VehicleFormModal
        open={openForm}
        toggleOpen={toggleOpenVehicleForm}
        showSnackbar={props.showSnackbar}
        vehicle={selectedVehicle}
        vehicles={props.vehicles}
        refetchVehicles={props.refetchVehicles}
        resetVehicle={resetVehicle}
        user={props.user}
      />
      <div id="draggable-dialog-title" style={{ paddingTop: '0.75em', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', gap: '1em', cursor: 'move' }}>
        <Typography fontWeight='bold' fontSize='1.5em'>
          Ökutæki
        </Typography>
        {props.user?.role.id !== Number(process.env.REACT_APP_ROLE_READ_ID) && (
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleNewClick}>Nýtt ökutæki</Button>
        )}
      </div>
      <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
        <CancelIcon fontSize='large' />
      </IconButton>
      <TextField
        id="searc-vehicles"
        label="Leita..."
        type="search"
        fullWidth
        variant='standard'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }} />
      <Box sx={{ height: '35em' }}>

        <DataGrid
          rows={filterData()}
          columns={columns}
          onRowClick={handleRowClick}
          loading={loading}
          slots={{
            noRowsOverlay: NoRowsOverlay
          }}
          columnVisibilityModel={{
            vehicleRegistrationNumber: !isMobile || !isTablet,
            vehicleWeight: !isMobile || !isTablet,
            //vehicleTare: !isMobile || !isTablet,
            vehicleTypeName: !isMobile || !isTablet,
            comment: !isMobile || !isTablet
          }}
        />

      </Box>
    </Dialog>
  );
};

export default VehicleModal;