import { FC, useEffect, useRef, useState } from 'react';
import { TitleFont, NewWeightNoteModalWrapper } from './NewWeightNoteModal.styled';
import { Autocomplete, Dialog, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { getLastGearForShip, postWeightNote } from '../../../../Services/LondunarkerfiAPIService';
import Contact from '../../../../Models/ContactModels/Contact';
import WeightNoteType from '../../../../Models/WeightNoteModels/WeightNoteType';
import Gear from '../../../../Models/WeightNoteModels/Gear';
import WeightNoteSubmit from '../../../../Models/WeightNoteModels/WeightNoteSubmit';
import Landing from '../../../../Models/LandingModels/Landing';
import User from '../../../../Models/UserModels/User';
import CommentIcon from '@mui/icons-material/Comment';
import CancelIcon from '@mui/icons-material/Cancel';
import ScaleIcon from '@mui/icons-material/Scale';
import PhishingIcon from '@mui/icons-material/Phishing';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { LoadingButton } from '@mui/lab';
import Permit from '../../../../Models/ReWeightingModels/Permit';
import ContactAutoComplete from '../../../../SharedComponents/AutoComplete/ContactAutoComplete';
import LandingStatuses from '../../../../Constants/LandingStatuses';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';

interface NewWeightNoteModalProps {
  newWeightNoteModalOpen: boolean;
  toggleNewWeightNoteModalOpen: () => void;
  weightNoteTypes: WeightNoteType[];
  fishingGears: Gear[];
  selectedLanding: Landing;
  user: User;
  fetchLandings: (setSelectedLandingId?: number, setSelectedWeightNoteId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  permits: Permit[];
}

/**
 * Functional component for NewWeightNoteModal.
 * Displays a modal for creating a new weightNote.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a user object.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the modal to create a new WeightNote.
 */

const NewWeightNoteModal: FC<NewWeightNoteModalProps> = (props: NewWeightNoteModalProps) => {

  const [selectedBuyer, setSelectedBuyer] = useState<Contact | null>(null);
  const [selectedReciever, setSelectedReciever] = useState<Contact | null>(null);
  const [selectedFishingGear, setSelectedFishingGear] = useState<Gear | null>(null);
  const [weightNoteTypes, setWeightNoteTypes] = useState<WeightNoteType[]>([]);
  const [selectedWeightNoteType, setSelectedWeightNoteType] = useState<WeightNoteType | null>(null);
  const [recieverPermits, setRecieverPermits] = useState<Permit[]>([]);
  const [selectedPermit, setSelectedPermit] = useState<Permit | null>(null);
  const [permitIsVisible, setPermitIsVisible] = useState<boolean>(false);
  const [focusedField, setFocusedField] = useState<'none' | 'buyer' | 'reciever' | 'weightNoteType' | 'fishingGear' | 'permit'>('none');
  const blurTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const defaultWeightNoteType = props.weightNoteTypes.find(type => type.id === WeightNoteTypes.HARBOUR_SCALE_ID);
  const noReweightingWeightNoteTypes = props.weightNoteTypes.filter(type =>
    type.id !== WeightNoteTypes.TO_REWEIGHING &&
    type.id !== WeightNoteTypes.TO_HOME_REWEIGHING &&
    type.id !== WeightNoteTypes.TO_REWEIGHTING_OVERSEAS &&
    type.id !== WeightNoteTypes.FINAL_REWEIGHTING &&
    type.id !== WeightNoteTypes.FROM_REWEIGHTER &&
    type.id !== WeightNoteTypes.FROM_REWEIGHTER_OVERSEAS
  );

  useEffect(() => {
    const fetchLastGear = async () => {
      try {
        const lastGear = await getLastGearForShip(props.selectedLanding?.ship?.shipRegistrationNumber);
        setSelectedFishingGear(lastGear);
      } catch (error) {
        logError(error);
      }
    };

    if (
      props.selectedLanding?.ship?.shipRegistrationNumber &&
      props.selectedLanding.landingStatus.id === LandingStatuses.OPEN
    ) {
      fetchLastGear();
    }
  }, [props.selectedLanding.landingStatus.id, props.selectedLanding?.ship]);

  useEffect(() => {
    // set the weightNoteTypes for component, since options could change based on permits.
    setWeightNoteTypes(noReweightingWeightNoteTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.weightNoteTypes]);

  useEffect(() => {
    if (defaultWeightNoteType) {
      setSelectedWeightNoteType(defaultWeightNoteType);
    }
  }, [defaultWeightNoteType]);

  useEffect(() => {
    if (selectedReciever) {
      const matchingPermits = props.permits.filter(
        (permit) => permit.contactId === selectedReciever.id
      );
      setRecieverPermits(matchingPermits);
      if (matchingPermits.length > 0) {
        setPermitIsVisible(true);
        setWeightNoteTypes(
          props.weightNoteTypes.filter(
            (type) =>
              type.id !== WeightNoteTypes.FINAL_REWEIGHTING &&
              type.id !== WeightNoteTypes.FROM_REWEIGHTER &&
              type.id !== WeightNoteTypes.FROM_REWEIGHTER_OVERSEAS
          )
        );
      } else {
        resetPermitState();
      }
    } else {
      resetPermitState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReciever, props.permits, props.weightNoteTypes]);

  const resetPermitState = () => {
    setRecieverPermits([]);
    setPermitIsVisible(false);
    setSelectedPermit(null);
    if (
      selectedWeightNoteType?.id === WeightNoteTypes.TO_REWEIGHING ||
      selectedWeightNoteType?.id === WeightNoteTypes.TO_HOME_REWEIGHING
    ) {
      handleWeightNoteTypeChange(null);
    }
  };

  useEffect(() => {
    // set Keyboard event.
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (submitButtonRef.current && focusedField === 'none') {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focusedField]);

  const handleBlur = () => {
    if (blurTimerRef.current) {
      clearTimeout(blurTimerRef.current);
    }

    blurTimerRef.current = setTimeout(() => {
      setFocusedField('none');
    }, 100);
  };

  const handleContactChange = (contact: Contact | null, type: 'buyer' | 'reciever' | '') => {
    if (type === 'buyer') {
      setSelectedBuyer(contact);
    }
    setSelectedReciever(contact);
  }

  const handleFishingGearChange = (params: Gear | null) => {
    setSelectedFishingGear(params);
  }

  const handleInputGearChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter gears
    const filteredGears = props.fishingGears.filter((gear) => {
      return gear.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredGears?.length === 1) {
      // If there's only one matching gear, set it as the value.
      handleFishingGearChange(filteredGears[0]);
    }
  }

  const handleWeightNoteTypeChange = (params: WeightNoteType | null) => {
    if (selectedReciever && (params?.id === WeightNoteTypes.TO_REWEIGHING || params?.id === WeightNoteTypes.TO_HOME_REWEIGHING)) {
      // weightNote is going to be reWeighted. If user only has one permit, set it automatically.
      const matchingPermits = props.permits.filter((permit) => permit.contactId === selectedReciever.id);
      if (matchingPermits.length === 1) {
        setSelectedPermit(matchingPermits[0])
      }
    }

    setSelectedWeightNoteType(params);
  }

  const handleInputWeightNoteTypeChange = (event: React.ChangeEvent<object>, value: string) => {
    // Filter weightNoteTypes
    const filteredTypes = props.weightNoteTypes.filter((type) => {
      return type.name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredTypes?.length === 1) {
      // If there's only one matching weightNoteType, set it as the value.
      handleWeightNoteTypeChange(filteredTypes[0]);
    }
  }

  const handlePermitChange = (params: Permit | null) => {
    setSelectedPermit(params);
  }

  const handleNewWeightNote = () => {
    if (!selectedReciever) {
      return;
    }
    if (!selectedReciever) {
      return;
    }
    if (!selectedFishingGear) {
      return;
    }
    if (!selectedWeightNoteType) {
      return;
    }
    if ((selectedWeightNoteType.id === WeightNoteTypes.TO_REWEIGHING || selectedWeightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING) && !selectedPermit) {
      return;
    }
    const newWeightNote: WeightNoteSubmit = {
      harbourId: props.user?.userPickedHarbour,
      weightNoteDate: new Date(),
      type: selectedWeightNoteType ? selectedWeightNoteType.id : undefined,
      gearId: selectedFishingGear ? selectedFishingGear.id : undefined,
      buyerId: selectedBuyer ? selectedBuyer.id : undefined,
      recieverId: selectedReciever ? selectedReciever.id : undefined,
      comment: comment,
      permitId: selectedPermit ? selectedPermit.permitId : undefined
    };
    AddWeightNote(newWeightNote);
  }

  async function AddWeightNote(newWeightNote: WeightNoteSubmit) {
    try {
      setLoading(true);
      const resp = await postWeightNote(props.selectedLanding.id, newWeightNote);
      props.showSnackbar('Vigtarnóta skráð!', 'success');
      props.toggleNewWeightNoteModalOpen();
      if (resp) {
        props.fetchLandings(props.selectedLanding.id, resp.data);
      }
      handleClose();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleClose = () => {
    if (!loading) {
      props.toggleNewWeightNoteModalOpen();
    }
    setWeightNoteTypes(noReweightingWeightNoteTypes);
    setSelectedBuyer(null);
    setSelectedReciever(null);
    if (selectedWeightNoteType?.id !== WeightNoteTypes.HARBOUR_SCALE_ID) {
      if (defaultWeightNoteType) {
        setSelectedWeightNoteType(defaultWeightNoteType);
      }
    }
    setSelectedPermit(null);
    setPermitIsVisible(false);
    setComment('');
  }

  return (
    <NewWeightNoteModalWrapper>
      <Dialog
        open={props.newWeightNoteModalOpen}
        onClose={handleClose}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <TitleFont id="draggable-dialog-title">
          Ný vigtarnóta
          <IconButton tabIndex={-1} size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>

        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <div style={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <ContactAutoComplete
              type='Kaupandi'
              onContactChange={(contact) => handleContactChange(contact, 'buyer')}
              onFocus={() => setFocusedField('buyer')}
              onBlur={handleBlur}
              autofocus={true}
              selectedContact={selectedBuyer}
            />
          </div>

          <div style={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <ContactAutoComplete
              type='Viðtakandi'
              onContactChange={(contact) => handleContactChange(contact, 'reciever')}
              onFocus={() => setFocusedField('reciever')}
              onBlur={handleBlur}
              autofocus={false}
              selectedContact={selectedReciever}
            />
          </div>
        </div>

        <Autocomplete
          disablePortal
          onInputChange={handleInputWeightNoteTypeChange}
          disabled={loading}
          options={weightNoteTypes}
          autoSelect={true}
          autoHighlight
          value={selectedWeightNoteType}
          onChange={(event, value) => handleWeightNoteTypeChange(value)}
          onFocus={() => setFocusedField('weightNoteType')}
          onBlur={handleBlur}
          blurOnSelect
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          sx={{ width: '100%' }}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          renderInput={(params) =>
            <TextField
              label="Tegund vigtarnótu"
              {...params}
              InputProps={{
                ...params.InputProps,
                required: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <ScaleIcon />
                  </InputAdornment>
                )
              }}
              required
            />}
        />

        {permitIsVisible && (

          <Autocomplete
            disablePortal
            disabled={loading}
            options={recieverPermits}
            value={selectedPermit}
            autoSelect={true}
            autoHighlight
            onChange={(e, weighingPermit) => handlePermitChange(weighingPermit)}
            onFocus={() => setFocusedField('permit')}
            onBlur={handleBlur}
            blurOnSelect
            getOptionLabel={(option) => option.homeAdress + ' ' + option.postalCode + ' ' + option.place + ' (' + option.permitId + ' ' + option.typeOfPermitName + ' - ' + option.contactType + ')'}
            sx={{ width: '100%' }}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <TextField
                label="Vigtunarleyfi"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <WorkspacePremiumIcon />
                    </InputAdornment>
                  )
                }}
                error={!selectedPermit && (selectedWeightNoteType?.id === WeightNoteTypes.TO_REWEIGHING || selectedWeightNoteType?.id === WeightNoteTypes.TO_HOME_REWEIGHING)}
                helperText={!selectedPermit && (selectedWeightNoteType?.id === WeightNoteTypes.TO_REWEIGHING || selectedWeightNoteType?.id === WeightNoteTypes.TO_HOME_REWEIGHING) ? 'Velja þarf vigtunarleyfi ef vigtarnótan er til endurvigtunar!' : ''}
              />}
          />)}

        <Autocomplete
          disablePortal
          onInputChange={handleInputGearChange}
          disabled={loading}
          options={props.fishingGears}
          autoSelect={true}
          autoHighlight
          value={selectedFishingGear}
          onChange={(event, value) => handleFishingGearChange(value)}
          onFocus={() => setFocusedField('fishingGear')}
          onBlur={handleBlur}
          blurOnSelect
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option.name || ""}
          sx={{ width: '100%' }}
          slotProps={{
            popper: {
              disablePortal: false,
            }
          }}
          renderInput={(params) =>
            <TextField
              label="Veiðarfæri"
              {...params}
              InputProps={{
                ...params.InputProps,
                required: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhishingIcon />
                  </InputAdornment>
                )
              }}
              required
            />}
        />

        <TextField
          disabled={loading}
          label="Athugasemd"
          onChange={(e) => setComment(e.target.value)}
          onBlur={handleBlur}
          value={comment}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
          }}
        />

        <LoadingButton
          ref={submitButtonRef}
          size="small"
          onClick={handleNewWeightNote}
          loading={loading}
          disabled={
            loading ||
            props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
            !selectedBuyer ||
            !selectedReciever ||
            !selectedFishingGear?.id ||
            !selectedWeightNoteType ||
            (selectedWeightNoteType.id === WeightNoteTypes.TO_REWEIGHING && !selectedPermit) ||
            (selectedWeightNoteType.id === WeightNoteTypes.TO_HOME_REWEIGHING && !selectedPermit)
          }
          variant="contained"
        >
          <span>Búa til vigtarnótu</span>
        </LoadingButton>

      </Dialog>
    </NewWeightNoteModalWrapper>
  );
}
export default NewWeightNoteModal;
