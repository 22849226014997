import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { ConfirmProvider } from "material-ui-confirm";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from "@mui/icons-material/Search";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EventIcon from '@mui/icons-material/Event';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Button, InputAdornment, TextField, useTheme, Tooltip, IconButton, BottomNavigation, BottomNavigationAction } from '@mui/material';
import User from '../../Models/UserModels/User';
import Gear from '../../Models/WeightNoteModels/Gear';
import Landing from '../../Models/LandingModels/Landing';
import Header from '../../SharedComponents/Header/Header';
import Permit from '../../Models/ReWeightingModels/Permit';
import LandingInfo from './Components/LandingInfo/LandingInfo';
import UserHarbours from '../../Models/UserModels/UserHarbours';
import LandingType from '../../Models/LandingModels/LandingType';
import WeightNote from '../../Models/WeightNoteModels/WeightNote';
import Vehicle from '../../Models/CatchRegistrationModels/Vehicle';
import Destiny from '../../Models/CatchRegistrationModels/Destiny';
import Condition from '../../Models/CatchRegistrationModels/Condition';
import WeightNoteInfo from './Components/WeightNoteInfo/WeightNoteInfo';
import WeightNoteType from '../../Models/WeightNoteModels/WeightNoteType';
import FishingArea from '../../Models/CatchRegistrationModels/FishingArea';
import NewLandingModal from './Components/NewLandingModal/NewLandingModal';
import FishingStock from '../../Models/CatchRegistrationModels/FishingStock';
import StorageMethod from '../../Models/CatchRegistrationModels/StorageMethod';
import SnackBarComponent from '../../SharedComponents/Snackbar/SnackBarComponent';
import CatchCombination from '../../Models/CatchRegistrationModels/CatchCombination';
import IncompleteLandingsTable from './Components/IncompleteLandingsTable/IncompleteLandingsTable';
import CatchRegistrationDataGrid from './Components/CatchRegistrationDataGrid/CatchRegistrationDataGrid';
import { Card, HeaderWrapper, HomeWrapper, IncompleteLandingsHeader, LeftWrapper, OverviewCardTitle, OverviewWrapper, RightWrapper } from './Home.styled';
import { getCatchCombinations, getConditions, getDeductionTypes, getDestinies, getFishingAreas, getFishingStocks, getGears, getLandingById, getLandings, getLandingTypes, getPermits, getStorageMethods, getUserHarbours, getVehiclesByHarbourId, getWeightNotes, getWeightNoteTypes } from '../../Services/LondunarkerfiAPIService';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import DeductionType from '../../Models/CatchRegistrationModels/DeductionType';
import LandingStatuses from '../../Constants/LandingStatuses';
import WeightNoteTypes from '../../Constants/WeightNoteTypes';
import MobilePages from '../../Constants/MobilePages';
import { debounce } from 'lodash';
import WeightNotesTable from './Components/WeightNotesTable/WeightNotesTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logError } from '../../Helpers/LogError';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

interface HomeProps {
  user: User;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/** 
 * Functional component for Home page.
 * @param {HomeProps} props - Takes in a user object.
 * @returns {JSX} - Responsible for returning the whole Home page.
 * 
 * Fetches landings and weightNotes as well as initial data used for dropdowns throughout the application.
 * 
 * Should render the following components:
 * - LandingInfo
 * - WeightNoteInfo
 * - IncompleteLandingsTable
 * - CatchRegistrationDataGrid
 * - SnackBarComponent
*/

const Home: FC<HomeProps> = (props) => {

  // Key codes and tab constants.
  const F5_KEY_CODE = 116;
  const F6_KEY_CODE = 117;
  const INCOMPLETE_LANDINGS_TAB = 0;
  const COMPLETE_LANDINGS_TAB = 1;

  const [tabValue, setTabValue] = React.useState(0);
  const [landingData, setLandingData] = useState<Landing[]>([]);
  const [completedLandings, setCompletedLandings] = useState<Landing[]>([]);
  const [filteredLandingData, setFilteredLandingData] = useState<Landing[]>([]);
  const [selectedLanding, setSelectedLanding] = useState({} as Landing);
  const [selectedWeightNote, setSelectedWeightNote] = useState({} as WeightNote);
  const [landingLoading, setLandingLoading] = useState<boolean>(false);
  const [weightNoteLoading, setWeightNoteLoading] = useState<boolean>(false);
  const [newLandingModalOpen, setNewLandingModalOpen] = useState(false);
  const [newWeightNoteModalOpen, setNewWeightNoteModalOpen] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [catchCombinations, setCatchCombinations] = useState<CatchCombination[] | null>();
  const [fishingAreas, setFishingAreas] = useState<FishingArea[]>([]);
  const [storageMethods, setStorageMethods] = useState<StorageMethod[]>([]);
  const [destinies, setDestinies] = useState<Destiny[]>([]);
  const [fishingStocks, setFishingStocks] = useState<FishingStock[]>([]);
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [fishingGear, setFishingGear] = useState<Gear[]>([]);
  const [weightNoteTypes, setWeightNoteTypes] = useState<WeightNoteType[]>([]);
  const [landingTypes, setLandingTypes] = useState<LandingType[]>([]);
  const [permits, setPermits] = useState<Permit[]>([]);
  const [userHarbours, setUserHarbours] = useState<UserHarbours[]>([]);
  const [harboursLoading, setHarboursLoading] = useState<boolean>(false);
  const [deductionTypes, setDeductionTypes] = useState<DeductionType[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [closedLandingsBadgeContent, setClosedLandingsBadgeContent] = useState(0);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState<Dayjs | null>(null);
  const [currentPage, setCurrentPage] = useState(MobilePages.LANDING_SELECTION);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [initialLandingLoad, setInitialLandingLoad] = useState(true);
  const [incompleteLandingsExpanded, setIncompleteLandingsExpanded] = useState(true);
  const theme = useTheme();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    handleTabSwitch();
  };

  const fetchData = useCallback(async () => {
    try {
      // Prioritize fetching landing to show data to the user as fast as possible.
      await fetchLandings();

      // Fetch other data in parallel without waiting for landing data.
      await Promise.all([
        fetchCompleteLandings(1),
        fetchUserHarbours(),
        getCombinations(),
        fetchCatchRegistrationData(),
        fetchVehicles(),
        fetchFishingGear(),
        fetchWeightNoteTypes(),
        fetchLandingTypes(),
        fetchPermits(),
        fetchDeductions()
      ]);

      // Keyboard shortcuts.
      window.addEventListener('keydown', keyboardNewLanding);
      window.addEventListener('keydown', keyboardNewWeightNote);
    } catch (error) {
      logError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize window dimensions and check if mobile view.
  const handleResize = () => {
    const initialWindowDimensions = getWindowDimensions();
    setIsMobileView(initialWindowDimensions.width < 1200);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // Focus on search bar when filteredLandingData changes.
    if (document.getElementById('searc-completed')) {
      document.getElementById('searc-completed')?.focus();
    }
  }, [filteredLandingData]);

  useEffect(() => {
    if (!isMobileView) {
      // When selected landing changes. Handle which tab should be selected.
      if (selectedLanding?.landingStatus?.id === LandingStatuses?.CLOSED || selectedLanding?.landingStatus?.id === LandingStatuses?.NEEDS_REWEIGHTING) {
        if (tabValue === INCOMPLETE_LANDINGS_TAB) {
          setTabValue(COMPLETE_LANDINGS_TAB);
        }
      }
      if (selectedLanding?.landingStatus?.id === LandingStatuses?.OPEN || selectedLanding?.landingStatus?.id === LandingStatuses?.CORRECTED) {
        if (tabValue === COMPLETE_LANDINGS_TAB) {
          setTabValue(INCOMPLETE_LANDINGS_TAB);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanding, tabValue]);

  const toggleNewLandingModalOpen = () => {
    setNewLandingModalOpen(!newLandingModalOpen);
  }

  const toggleNewWeightNoteModalOpen = () => {
    setNewWeightNoteModalOpen(!newWeightNoteModalOpen);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keyboardNewLanding = (e: any) => {
    const allModalsClosed = document.body.getElementsByClassName("MuiDialog-root")?.length === 0;
    if ((e.keyCode) === F5_KEY_CODE) {
      if (e.ctrlKey) {
        // allow for native browser hard refresh. (Ctrl + F5)
        return;
      }
      e.preventDefault();
      if (allModalsClosed) {
        toggleNewLandingModalOpen();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keyboardNewWeightNote = (e: any) => {
    const allModalsClosed = document.body.getElementsByClassName("MuiDialog-root")?.length === 0;
    if ((e.keyCode) === F6_KEY_CODE) {
      e.preventDefault();
      if (allModalsClosed) {
        toggleNewWeightNoteModalOpen();
      }
    }
  };

  const handleTabSwitch = () => {
    // handles when user clicks on switch to show completed landings.
    if (tabValue === INCOMPLETE_LANDINGS_TAB) {
      setTabValue(COMPLETE_LANDINGS_TAB);
    } else if (tabValue === COMPLETE_LANDINGS_TAB) {
      setTabValue(INCOMPLETE_LANDINGS_TAB);
    }

    // If user is on mobile view, we allow him to select a landing himself.
    if (!isMobileView) {
      if (tabValue === INCOMPLETE_LANDINGS_TAB) {
        if (searchQuery && filteredLandingData?.length > 0) {
          handleSelectLanding(filteredLandingData[0]);
        } else if (completedLandings?.length > 0) {
          handleSelectLanding(completedLandings[0]);
        }
        else {
          handleSelectLanding({} as Landing);
        }
      } else {
        if (landingData?.length > 0) {
          handleSelectLanding(landingData[0]);
        } else {
          handleSelectLanding({} as Landing);
        }
      }
    }
  };

  const fetchLandings = async (setSelectedLandingId?: number, setSelectedWeightNoteId?: number) => {
    /* Fetches incomplete landings and handles selection of landing and weightNote. child components call this! */
    try {
      const landingData = await getLandings(props.user.userPickedHarbour, false, 1);
      setLandingData(landingData);
      let newSelectedLanding;

      if (landingData?.length > 0) {
        // If setSelectedLandingId was requested we set newSelectedLanding as that landing and handle selection.
        if (setSelectedLandingId) {
          newSelectedLanding = landingData.find(landing => landing.id === setSelectedLandingId);
          if (tabValue === COMPLETE_LANDINGS_TAB) {
            handleTabSwitch();
          }
        }
        if (!newSelectedLanding) {
          newSelectedLanding = landingData[0];
        }
      } else {
        newSelectedLanding = {} as Landing;
      }
      handleSelectLanding(
        newSelectedLanding,
        setSelectedLandingId ? newSelectedLanding.id : undefined,
        setSelectedLandingId ? landingData : undefined,
        setSelectedWeightNoteId ? setSelectedWeightNoteId : undefined
      );
      setLandingLoading(false);
    } catch (error) {
      setLandingLoading(false);
      logError(error);
    }
  };

  const handleSelectLanding = async (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => {

    // If it is the initial load in mobile view, we do not want to change the page.
    if (initialLandingLoad && getWindowDimensions().width < 1200) {
      setInitialLandingLoad(false);
      return;
    } else {
      setInitialLandingLoad(false);
      setCurrentPage(MobilePages.WEIGHT_NOTE_SELECTION);
    }

    if (newLandingId && landings) {
      const requestedLanding = landings.find(landing => landing?.id === newLandingId);
      if (requestedLanding) {
        landing = requestedLanding;
      }
    }
    if (landing.id) {
      if (completedLandings.find(completedLanding => completedLanding.id === landing.id)) {
        // If landing is found in completed landings, we set selectedLanding as that landing.
        setSelectedLanding(landing);
      }
      else if (landingData.find(incompleteLanding => incompleteLanding.id === landing.id)) {
        // If landing is found in incomplete landings, we set selectedLanding as that landing.
        setSelectedLanding(landing);
      }
      else {
        // If landing is not found in either completed or incomplete landings, the user requested a landing to be selected.
        if (landingData?.length > 0) {
          const newLanding = await getLandingById(newLandingId ? newLandingId : landing.id);
          landing = newLanding;

          if (newLanding.landingStatus.id === LandingStatuses.CLOSED) {
            setCompletedLandings((prevData) => [newLanding, ...prevData]);
            setSelectedLanding(landing);
          } else {
            setSelectedLanding(landing);
          }
        } else {
          setSelectedLanding(landing);
        }
      }
      const weightNotes = await fetchWeightNotes(landing, newWeightNoteId, true);
      landing.weightNotes = weightNotes;
    } else {
      setSelectedLanding(landing || {} as Landing);
    }
  }

  const fetchWeightNotes = async (landing: Landing, setSelectedWeightNoteId?: number, isLandingSelection?: boolean) => {
    try {
      setWeightNoteLoading(true);
      const weightNotesData = await getWeightNotes(landing?.id, true);
      const updatedSelectedLanding = { ...landing, weightNotes: weightNotesData };
      setSelectedLanding(updatedSelectedLanding);

      let newSelectedWeightNote;

      if (weightNotesData?.length > 0) {
        // If selectedWeightNoteId was requested, set newSelectedWeightNote as that weight note.
        if (setSelectedWeightNoteId) {
          newSelectedWeightNote = weightNotesData.find(weightNote => weightNote.id === setSelectedWeightNoteId);
        }
        if (!newSelectedWeightNote && landing.landingStatus.id === LandingStatuses.OPEN) {
          newSelectedWeightNote = weightNotesData.find(weightNote =>
            weightNote.isCredit === false &&
            weightNote.undoWeightNoteId == null &&
            !(
              weightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_SAMPLE_ID &&
              weightNote.isClosed
            )
          );
          if (!newSelectedWeightNote) {
            newSelectedWeightNote = {} as WeightNote;
          }
        }
        if (!newSelectedWeightNote) {
          if (weightNotesData.some(weightNote => weightNote.isClosed === false) && 
          updatedSelectedLanding.landingStatus.id !== LandingStatuses.NEEDS_REWEIGHTING) {
            newSelectedWeightNote = weightNotesData.find(weightNote => weightNote.isClosed === false);
          } else {
            newSelectedWeightNote = weightNotesData.find(weightNote =>
              weightNote.isCredit === false &&
              weightNote.undoWeightNoteId == null &&
              weightNote.weightNoteType.id !== WeightNoteTypes.TO_REWEIGHING &&
              weightNote.weightNoteType.id !== WeightNoteTypes.TO_HOME_REWEIGHING &&
              weightNote.weightNoteType.id !== WeightNoteTypes.FINAL_REWEIGHTING
            );
          }
          if (!newSelectedWeightNote) {
            newSelectedWeightNote = {} as WeightNote;
          }
        }
      } else {
        newSelectedWeightNote = {} as WeightNote;
      }

      // If the user is clicking on a landing in mobile view, we do not want to auto select a weight note.
      if (!(isMobileView && isLandingSelection)) {
        handleSelectWeightNote(
          newSelectedWeightNote,
          setSelectedWeightNoteId ? newSelectedWeightNote.id : undefined,
          setSelectedWeightNoteId ? weightNotesData : undefined
        );
      }

      setWeightNoteLoading(false);
      return weightNotesData;
    } catch (error) {
      setWeightNoteLoading(false);
      logError(error);
      return [];
    }
  };

  const handleSelectWeightNote = async (weightNote?: WeightNote, newWeightNoteId?: number, weightNotes?: WeightNote[]) => {

    // If it is in mobile view, we want to change the page.
    if (getWindowDimensions().width < 1200) {
      setCurrentPage(MobilePages.WEIGHT_NOTE_DETAILS);
    }

    // Find best match for selectedWeightNote based on conditions from children.
    if (newWeightNoteId) {
      if (weightNotes) {
        weightNote = weightNotes.find(weightNote => weightNote.id === newWeightNoteId);
      } else if (!weightNote) {
        if (selectedLanding.weightNotes) {
          weightNote = selectedLanding.weightNotes.find(weightNote => weightNote?.id === newWeightNoteId);
        }
      } else {
        const landingWithWeightNote = landingData.find(landing => landing.weightNotes.some(weightNote => weightNote?.id === newWeightNoteId));
        if (landingWithWeightNote) {
          weightNote = landingWithWeightNote.weightNotes.find(weightNote => weightNote?.id === newWeightNoteId);
        }
      }
    }
    setSelectedWeightNote(weightNote || {} as WeightNote);
  };

  const fetchCompleteLandings = async (pageNumber: number, refetching?: boolean, requestedLandingId?: number, requestedWeightNoteId?: number) => {
    try {
      setLandingLoading(true);
      const requestedLandingExists = completedLandings.find(landing => landing.id === requestedLandingId);
      if (requestedLandingExists) {
        fetchWeightNotes(requestedLandingExists, requestedWeightNoteId);
        setLandingLoading(false);
        return;
      }
      const completedLandingsData = await getLandings(props.user.userPickedHarbour, true, pageNumber);
      if (refetching) {
        setCompletedLandings(completedLandingsData);
        const completedLandingsCount = completedLandingsData.filter(landing => landing.landingStatus.id === LandingStatuses.NEEDS_REWEIGHTING)?.length;
        setClosedLandingsBadgeContent(completedLandingsCount);
        const selectedLanding = completedLandingsData.find(landing => landing.id === requestedLandingId);
        fetchWeightNotes(selectedLanding || completedLandingsData[0], requestedWeightNoteId);
      } else {
        // make sure that there is no id in the prevData which is the same as completedLandingsData from getLandings
        const prevDataIds = completedLandings.map(landing => landing.id);
        const removeDuplicates = completedLandingsData.filter(landing => !prevDataIds.includes(landing.id));
        setCompletedLandings((prevData) => [...prevData, ...removeDuplicates]);
        setClosedLandingsBadgeContent(() => {
          const completedLandingsCount = [...completedLandings, ...completedLandingsData].filter(
            (landing, index, self) =>
              landing.landingStatus.id === LandingStatuses.NEEDS_REWEIGHTING &&
              index === self.findIndex((l) => l.id === landing.id)
          )?.length;
          return completedLandingsCount;
        });
      }
      setLandingLoading(false);
    } catch (error) {
      setLandingLoading(false);
      logError(error);
    }
  };

  const fetchFilteredLandings = async (pageNumber: number, searchCondition?: string, landingStarts?: string) => {
    try {
      setLandingLoading(true);
      const filteredData = await getLandings(
        props.user?.userPickedHarbour,
        true,
        pageNumber,
        searchCondition ? searchCondition : searchQuery,
        landingStarts && landingStarts
      );
      if (searchCondition || landingStarts) {
        setFilteredLandingData(filteredData);
      } else {
        const prevDataIds = filteredLandingData.map(landing => landing?.id);
        const removeDuplicates = filteredData.filter(landing => !prevDataIds.includes(landing?.id));
        setFilteredLandingData((prevData) => [...prevData, ...removeDuplicates]);
      }
      if(filteredData.length === 1) {
        handleSelectLanding(filteredData[0]);
      }
      setLandingLoading(false);
    } catch (error) {
      setLandingLoading(false);
      logError(error);
    }
  };

  // We fetch allowed combinations for catch once on initial load.
  const getCombinations = async () => {
    try {
      const combinations = await getCatchCombinations();
      setCatchCombinations(combinations);
    } catch (error) {
      logError(error);
    }
  };

  // We fetch available dropdown values for catch registration once on initial load.
  const fetchCatchRegistrationData = async () => {
    try {
      const fishingAreasData = await getFishingAreas();
      const storageMethodsData = await getStorageMethods();
      const destiniesData = await getDestinies();
      const fishingStocksData = await getFishingStocks();
      const conditionsData = await getConditions();

      setFishingAreas(fishingAreasData);
      setStorageMethods(storageMethodsData);
      setDestinies(destiniesData);
      setFishingStocks(fishingStocksData);
      setConditions(conditionsData);
    } catch (error) {
      logError(error);
    }
  };

  // We fetch available vehciles for catch registration once on initial load.
  const fetchVehicles = async () => {
    try {
      const vehicleData = await getVehiclesByHarbourId(props.user?.userPickedHarbour);
      setVehicles(vehicleData);
    } catch (error) {
      logError(error);
    }
  };

  // We fetch available fishing gear once on initial load.
  const fetchFishingGear = async () => {
    try {
      const gear = await getGears();
      setFishingGear(gear);
    } catch (error) {
      logError(error);
    }
  }

  // We fetch available weightNoteTypes once on initial load.
  const fetchWeightNoteTypes = async () => {
    try {
      const weightNoteTypes = await getWeightNoteTypes();
      setWeightNoteTypes(weightNoteTypes);
    } catch (error) {
      logError(error);
    }
  }

  // We fetch available landingTypes once on initial load.
  const fetchLandingTypes = async () => {
    try {
      const landingTypes = await getLandingTypes();
      setLandingTypes(landingTypes);
    } catch (error) {
      logError(error);
    }
  }

  // We fetch available permits once on initial load.
  const fetchPermits = async () => {
    try {
      const permits = await getPermits();
      setPermits(permits);
    } catch (error) {
      logError(error);
    }
  };

  // We fetch deduction types once on inital load.
  const fetchDeductions = async () => {
    try {
      const deductionTypesData = await getDeductionTypes();
      setDeductionTypes(deductionTypesData);
    } catch (error) {
      logError(error);
    }
  };

  const fetchUserHarbours = async () => {
    try {
      setHarboursLoading(true);
      const userHarbours = await getUserHarbours();
      if (userHarbours) {
        setUserHarbours(userHarbours);
      }
    } catch (error) {
      logError(error);
    } finally {
      setHarboursLoading(false);
    }
  };

  const showSnackbar = (message: string, severity: string) => {
    if (severity === 'success') {
      setSuccessMsg(message);
      setSuccess(true);
    } else if (severity === 'error') {
      setErrorMsg(message);
      setError(true);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(false);
  };

  // debounce search input
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFilteredLandings = useCallback(
    debounce((value) => {
      fetchFilteredLandings(1, value, dateFilterValue ? dateFilterValue.format('DD.MM.YYYY') : '');
    }, 700),
    [dateFilterValue]
  );

  useEffect(() => {
    debouncedFetchFilteredLandings.cancel();
  }, [debouncedFetchFilteredLandings]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setSelectedLanding({} as Landing);
    setCompletedLandings([]);
    if (!event.target.value) {
      // if search input is empty, initialize and fetch first page of complete landings
      if (dateFilterValue) {
        fetchFilteredLandings(1, '', dateFilterValue.format('DD.MM.YYYY'));
      } else {
        setFilteredLandingData([]);
        fetchCompleteLandings(1, true);
      }
    } else {
      // always fetch first page on change of search input
      debouncedFetchFilteredLandings(event.target.value);
    }
  };

  const refetchBothTypeOfLandings = async () => {
    await fetchCompleteLandings(1, true);
    await fetchLandings();
    await fetchVehicles();
    setSearchQuery('');
    setDateFilterValue(null);
    setFilteredLandingData([]);
  }

  const handleDateFilterChange = (date: Dayjs | null) => {
    setDateFilterValue(date);
    if (date) {
      const formattedDate = date.format('DD.MM.YYYY');
      fetchFilteredLandings(1, searchQuery, formattedDate);
    } else {
      fetchFilteredLandings(1, searchQuery);
    }
  }

  const handleClearDateFilter = () => {
    setDateFilterValue(null);
    fetchFilteredLandings(1, searchQuery);
  }

  // We pass this to WeightNoteInfo component to be able to render it in the right location.
  const CatchRegistration = (
    <CatchRegistrationDataGrid
      user={props.user}
      allowedCombinations={catchCombinations ? catchCombinations : null}
      fishAreas={fishingAreas}
      storageMethods={storageMethods}
      destinies={destinies}
      fishingStocks={fishingStocks}
      conditions={conditions}
      vehicles={vehicles}
      gears={fishingGear}
      deductionTypes={deductionTypes}
      showSnackbar={showSnackbar}
      fetchWeightNotes={fetchWeightNotes}
      selectedLanding={selectedLanding}
      selectedWeightNote={selectedWeightNote}
      loading={weightNoteLoading}
      isMobileView={isMobileView}
      refetchVehicles={fetchVehicles}
    />
  );

  return (
    <HomeWrapper>
      <ConfirmProvider>
        <HeaderWrapper>
          <Header
            user={props.user}
            refetchLandings={refetchBothTypeOfLandings}
            selectedPage='Löndunarkerfi'
            isAuthorized={true}
            refetchVehicles={fetchVehicles}
            vehicles={vehicles}
            fishingGears={fishingGear}
            fishingStock={fishingStocks}
            showSnackbar={showSnackbar}
            setSelectedLanding={handleSelectLanding}
            harbours={userHarbours}
            harboursLoading={harboursLoading}
            refetchHarbours={fetchUserHarbours}
            darkMode={props.darkMode}
            setDarkMode={props.setDarkMode}
          />
        </HeaderWrapper>

        <SnackBarComponent successMsg={successMsg} isOpen={success} onClose={handleSnackbarClose} autoHideDurationMS={3000} severity={'success'} />
        <SnackBarComponent successMsg={errorMsg} isOpen={error} onClose={handleSnackbarClose} autoHideDurationMS={6000} severity={'error'} />

        <OverviewWrapper style={{ flexDirection: isMobileView ? 'column' : 'row' }}>
          {(((currentPage === MobilePages.LANDING_SELECTION && isMobileView)) || !isMobileView) &&
            <LeftWrapper style={{ width: isMobileView ? '100%' : '' }}>

              {/* We hide the incomplete landings table if the user wants. */}
              {incompleteLandingsExpanded && !isMobileView || isMobileView ? (
                <Card style={{
                  height: isMobileView ? '100%' : 'calc(100% - 2em)',
                  width: isMobileView ? '100%' : '',
                  margin: isMobileView ? '0' : '1em 0 1em 1em',
                  backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
                  borderRadius: isMobileView ? '0' : '',
                  transition: 'all 0.5s ease-in-out' // Add transition for smoothness.
                }}>
                  <IncompleteLandingsHeader>
                    <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', flexWrap: 'wrap' }}>
                      <OverviewCardTitle>
                        Landanir
                      </OverviewCardTitle>

                      <Button
                        variant='contained'
                        disabled={landingLoading || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
                        onClick={toggleNewLandingModalOpen}
                        sx={{ margin: '0.75em' }}
                        size='medium'
                      >
                        <AddIcon />
                        <Tooltip title='F5' arrow>
                          <span>Ný löndun</span>
                        </Tooltip>
                      </Button>
                    </span>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab
                          icon={
                            <Badge badgeContent={landingData?.length} color="info"><LockOpenIcon /></Badge>
                          }
                          label="OPNAR"
                          style={{ width: "50%" }}
                          {...a11yProps(INCOMPLETE_LANDINGS_TAB)}
                          disabled={landingLoading || weightNoteLoading}
                        />
                        <Tab
                          icon={
                            <Badge badgeContent={closedLandingsBadgeContent} color='error'><LockIcon /></Badge>
                          }
                          label="LOKAÐAR"
                          style={{ width: "50%" }}
                          {...a11yProps(COMPLETE_LANDINGS_TAB)}
                          disabled={landingLoading || weightNoteLoading}
                        />
                      </Tabs>
                    </Box>

                    {tabValue === COMPLETE_LANDINGS_TAB ?
                      <Box sx={{ margin: '0.5em' }}>
                        <TextField
                          id="searc-completed"
                          placeholder="Leita..."
                          variant='standard'
                          sx={{
                            width: '100%',
                            "& fieldset":
                            {
                              border: 'none'
                            }
                          }}
                          value={searchQuery}
                          autoComplete="off"
                          onChange={handleSearchChange}
                          autoFocus={!isMobileView}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <SearchIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
                                  <Tooltip title={dateFilterValue ? dateFilterValue.format('DD.MM.YYYY') : 'Sía eftir dagsetningu'}>
                                    <IconButton onClick={() => setDateFilterOpen(true)}>
                                      <Badge badgeContent={dateFilterValue ? 1 : null} color="primary">
                                        <EventIcon />
                                      </Badge>
                                    </IconButton>
                                  </Tooltip>
                                  {dateFilterValue && (
                                    <Tooltip title="Hreinsa">
                                      <IconButton onClick={handleClearDateFilter}>
                                        <CloseIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <MobileDatePicker
                                    open={dateFilterOpen}
                                    onOpen={() => setDateFilterOpen(true)}
                                    onClose={() => setDateFilterOpen(false)}
                                    value={dateFilterValue}
                                    onAccept={(newValue: Dayjs | null) => handleDateFilterChange(dayjs(newValue))}
                                    format='DD.MM.YYYY'
                                    slotProps={{
                                      textField: {
                                        style: { display: 'none' }
                                      }
                                    }}
                                    disableFuture
                                  />
                                </LocalizationProvider>
                              </InputAdornment>
                            ),
                            disableUnderline: true
                          }} />
                      </Box> : ''
                    }
                  </IncompleteLandingsHeader>

                  <IncompleteLandingsTable
                    landingLoading={landingLoading}
                    landingData={tabValue === INCOMPLETE_LANDINGS_TAB ? landingData : filteredLandingData?.length > 0 || searchQuery || dateFilterValue ? filteredLandingData : completedLandings}
                    selectedLanding={selectedLanding}
                    handleSelectLanding={handleSelectLanding}
                    fetchCompletedLandings={fetchCompleteLandings}
                    fetchFilteredLandings={fetchFilteredLandings}
                    hasFilter={searchQuery ? true : false}
                    shouldHandleScroll={tabValue === COMPLETE_LANDINGS_TAB}
                  />

                  {/* Hide landings button */}
                  {!isMobileView && (
                    <Tooltip title="Fela landanir" arrow>
                      <Box
                        onClick={() => setIncompleteLandingsExpanded(false)}
                        style={{
                          backgroundColor: theme.palette.mode === "dark" ? "#272727" : 'white',
                          cursor: 'pointer',
                          zIndex: 1000,
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '4em',
                          transition: 'all 0.5s ease-in-out', // Add transition for smoothness.
                          borderTop: '1px solid rgba(231, 234, 243, 1)',
                        }}
                      >
                        <IconButton>
                          <KeyboardArrowLeftIcon style={{ color: 'black' }} />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                </Card>
              )
                :
                <Card
                  onClick={() => setIncompleteLandingsExpanded(true)}
                  style={{
                    backgroundColor: theme.palette.mode === "dark" ? "#272727" : 'white',
                    padding: '0.5em',
                    margin: '1em 0px 1em 1em',
                    cursor: 'pointer',
                    zIndex: 1000,
                    width: '2.5em',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100% - 2em)',
                    transition: 'all 0.5s ease-in-out' // Add transition for smoothness.
                  }}
                >
                  <Tooltip title="Sýna landanir" arrow>
                    <IconButton>
                      <KeyboardArrowRightIcon style={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </Card>
              }

              <WeightNotesTable
                loading={weightNoteLoading}
                selectedLanding={selectedLanding}
                selectedWeightNote={selectedWeightNote}
                weightNoteTypes={weightNoteTypes}
                user={props.user}
                weightNoteLoading={weightNoteLoading}
                handleSelectWeightNote={handleSelectWeightNote}
                currentPage={currentPage}
                toggleNewWeightNoteModalOpen={toggleNewWeightNoteModalOpen}
                isMobileView={isMobileView}
                incompleteLandingsExpanded={incompleteLandingsExpanded}
              />
            </LeftWrapper>
          }

          {(((currentPage === MobilePages.WEIGHT_NOTE_SELECTION || currentPage === MobilePages.WEIGHT_NOTE_DETAILS) && isMobileView) || !isMobileView) &&
            <RightWrapper style={{ width: isMobileView ? '100%' : '' }}>
              {/* Render empty card if no landing is selected */}
              {(((currentPage === MobilePages.WEIGHT_NOTE_SELECTION || currentPage === MobilePages.WEIGHT_NOTE_DETAILS) && isMobileView) || !isMobileView) &&
                (
                  // if landing is selected, render landing info and weight note info
                  // if no landing is selected, render empty card
                  selectedLanding?.id ?
                    <>
                      {((isMobileView && currentPage === MobilePages.WEIGHT_NOTE_SELECTION) || !isMobileView) && (
                        <Card style={{
                          width: 'auto',
                          backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
                          margin: isMobileView ? '0' : '',
                          borderRadius: isMobileView ? '0' : ''
                        }}>
                          <LandingInfo
                            loading={landingLoading || weightNoteLoading}
                            landingData={landingData}
                            selectedLanding={selectedLanding}
                            selectedWeightNote={selectedWeightNote}
                            landingTypes={landingTypes}
                            fetchLandings={fetchLandings}
                            fetchCompleteLandings={fetchCompleteLandings}
                            setSelectedLanding={handleSelectLanding}
                            showSnackbar={showSnackbar}
                            permits={permits}
                            user={props.user}
                            harbours={userHarbours}
                            newWeightNoteModalOpen={newWeightNoteModalOpen}
                            toggleNewWeightNoteModalOpen={toggleNewWeightNoteModalOpen}
                            fishingGears={fishingGear}
                            weightNoteTypes={weightNoteTypes}
                            isMobileView={isMobileView}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        </Card>
                      )}

                      {(currentPage === MobilePages.WEIGHT_NOTE_SELECTION && isMobileView) &&
                        <WeightNotesTable
                          loading={weightNoteLoading}
                          selectedLanding={selectedLanding}
                          selectedWeightNote={selectedWeightNote}
                          weightNoteTypes={weightNoteTypes}
                          user={props.user}
                          weightNoteLoading={weightNoteLoading}
                          handleSelectWeightNote={handleSelectWeightNote}
                          currentPage={currentPage}
                          toggleNewWeightNoteModalOpen={toggleNewWeightNoteModalOpen}
                          isMobileView={isMobileView}
                          incompleteLandingsExpanded={incompleteLandingsExpanded}
                        />
                      }

                      {((currentPage === MobilePages.WEIGHT_NOTE_DETAILS && isMobileView) || !isMobileView) && (
                        <WeightNoteInfo
                          loading={weightNoteLoading}
                          selectedLanding={selectedLanding}
                          selectedWeightNote={selectedWeightNote}
                          fishingGears={fishingGear}
                          weightNoteTypes={weightNoteTypes}
                          fetchLandings={fetchLandings}
                          showSnackbar={showSnackbar}
                          permits={permits}
                          user={props.user}
                          CatchRegistrationDataGrid={CatchRegistration}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          fetchCompleteLandings={fetchCompleteLandings}
                          isMobileView={isMobileView}
                        />
                      )}
                    </>
                    :
                    <Card style={{
                      backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined,
                      height: '100%',
                      marginBottom: '1em',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'calc(100% - 2em)'
                    }}>
                      <p>Engin löndun valin</p>
                    </Card>
                )
              }
            </RightWrapper>
          }
        </OverviewWrapper>

        {(isMobileView && currentPage != MobilePages.LANDING_SELECTION) && (
          <Box sx={{ width: '100%', zIndex: 99, borderTop: 0, position: 'fixed', bottom: 0, left: 0 }}>
            <BottomNavigation
              showLabels
              value={-1}
            >
              {(isMobileView && currentPage === MobilePages.WEIGHT_NOTE_SELECTION) && (
                <BottomNavigationAction label="Til baka" onClick={() => setCurrentPage(MobilePages.LANDING_SELECTION)} icon={<ArrowBackIcon />} />
              )}
              {(isMobileView && currentPage === MobilePages.WEIGHT_NOTE_DETAILS) && (
                <BottomNavigationAction label="Til baka" onClick={() => setCurrentPage(MobilePages.WEIGHT_NOTE_SELECTION)} icon={<ArrowBackIcon />} />
              )}
            </BottomNavigation>
          </Box>
        )}

        <NewLandingModal
          newLandingModalOpen={newLandingModalOpen}
          toggleNewLandingModalOpen={toggleNewLandingModalOpen}
          landingTypes={landingTypes}
          user={props.user}
          fetchLandings={fetchLandings}
          showSnackbar={showSnackbar}
          landings={landingData}
        />
      </ConfirmProvider>

    </HomeWrapper >
  );
}

export default Home;