import styled from 'styled-components';
import { Typography } from '@mui/material';

export const TitleFont = styled.span`
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    gap: 0.4em;
    line-height: 1.5em;
    cursor: move;
`;

export const ItemRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1em; /* Spacing between cards */
    width: 100%; /* Fill the available width */
    flex-direction: column;
    flex-flow: wrap;
`;

export const TypographyStyled = styled(Typography)`
    text-align: center;
`;