import { FC, useEffect, useState } from 'react';
import { CardActionStyled, CardContainer, CardMediaStyled, CardStyled, TypographyStyled, UnauthorizedWrapper } from './Unauthorized.styled';
import Header from '../../SharedComponents/Header/Header';
import { Button, CardContent } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { getUserUnhandled } from '../../Services/LondunarkerfiAPIService';

const Unauthorized: FC = () => {

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [secondaryErrorMessage, setSecondaryErrorMessage] = useState<string>("");
  
  useEffect(() => {
    const handleLoginChange = async () => {
      try {
        // Fetch user data, passing 'false' as a parameter.
        await getUserUnhandled();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // Check if the error is a 401 Unauthorized.
        if (error.response && error.response.status === 401) {
          // Check if the body of the response contains "Token has expired".
          const responseBody = error.response.data; // Assuming the body is in `data`
          if (responseBody === "Token has expired") {
            setErrorMessage("Auðkenning rann út.");
            setSecondaryErrorMessage("");
          } else {
            setErrorMessage("Auðkenning tókst en þú hefur ekki aðgang að þessu kerfi");
            setSecondaryErrorMessage("Ef þú heldur að það sé ekki rétt getur þú tilkynnt það til Fiskistofu.");
          }
        }
      }
    };
    handleLoginChange();
  }, []);

  const handleMail = () => {
    window.location.href = 'mailto:fiskistofa@fiskistofa.is';
  };

  return (
    <UnauthorizedWrapper>
      <Header isAuthorized={false} />
      <CardContainer>
        <CardStyled>
          <CardMediaStyled
            image={require('../../images/Unauthorized.jpg')}
            title="unauthorized-image"
          />
          <CardContent>
            <TypographyStyled gutterBottom variant="h5">
              {errorMessage}            
            </TypographyStyled>
            <TypographyStyled variant="body2" color="text.secondary">
              {secondaryErrorMessage}
            </TypographyStyled>
          </CardContent>
          <CardActionStyled>
            <Button variant="outlined" endIcon={<MailIcon />} onClick={handleMail}>
              Tilkynna
            </Button>
          </CardActionStyled>
        </CardStyled>
      </CardContainer>
    </UnauthorizedWrapper>
  );
}
export default Unauthorized;
