import { FC, useEffect, useState } from "react";
import { ReWeightingWrapper, Splitter } from "./ReWeighting.styled";
import Header from "../../SharedComponents/Header/Header";
import WeightNote from "../../Models/WeightNoteModels/WeightNote";
import SnackBarComponent from "../../SharedComponents/Snackbar/SnackBarComponent";
import User from "../../Models/UserModels/User";
import CatchCombination from "../../Models/CatchRegistrationModels/CatchCombination";
import FishingArea from "../../Models/CatchRegistrationModels/FishingArea";
import StorageMethod from "../../Models/CatchRegistrationModels/StorageMethod";
import Destiny from "../../Models/CatchRegistrationModels/Destiny";
import FishingStock from "../../Models/CatchRegistrationModels/FishingStock";
import Condition from "../../Models/CatchRegistrationModels/Condition";
import { getCatchCombinations, getConditions, getDestinies, getFishingAreas, getFishingStocks, getLandingsReweighting, getStorageMethods } from "../../Services/EndurvigtunAPIService";
import ReWeightingList from "./Components/ReWeightingList/ReWeightingList";
import { BottomNavigation, BottomNavigationAction, Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import PollIcon from '@mui/icons-material/Poll';
import ReWeightingInfo from "./Components/ReWeightingInfo/ReWeightingInfo";
import { ConfirmProvider } from "material-ui-confirm";
import ReWeightingLanding from "../../Models/ReWeightingModels/ReWeightingLanding";
import { logError } from "../../Helpers/LogError";

interface ReWeightingProps {
  user: User;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const ReWeighting: FC<ReWeightingProps> = (props) => {
  const [landings, setLandings] = useState<ReWeightingLanding[]>([]);
  const [selectedLanding, setSelectedLanding] = useState({} as ReWeightingLanding);
  const [selectedWeightNote, setSelectedWeightNote] = useState({} as WeightNote);
  const [catchCombinations, setCatchCombinations] = useState<CatchCombination[] | null>();
  const [fishingAreas, setFishingAreas] = useState<FishingArea[]>([]);
  const [storageMethods, setStorageMethods] = useState<StorageMethod[]>([]);
  const [destinies, setDestinies] = useState<Destiny[]>([]);
  const [fishingStocks, setFishingStocks] = useState<FishingStock[]>([]);
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [navigationValue, setNavigationValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));


  useEffect(() => {
    fetchLandings(1, true);
    fetchCatchRegistrationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (landings?.length > 0) {
      const openLandings = landings.filter(landing => landing.weightNote && !landing.weightNote.isClosed);
      if (openLandings?.length > 0) {
        setSelectedLanding(openLandings[0]);
        setSelectedWeightNote(openLandings[0].weightNote);
      } else {
        setSelectedLanding(landings[0]);
        if (landings[0].weightNote) {
          setSelectedWeightNote(landings[0].weightNote);
        }
      }
    }
  }, [landings]);

  const fetchLandings = async (pageNumber: number, refetching: boolean) => {
    try {
      setLoading(true);
      const landingData = await getLandingsReweighting(pageNumber, props.user.ssn);
      if (refetching) {
        setLandings(landingData);
      } else {
        setLandings((prevLandings) => {
          const newLandings = [...prevLandings, ...landingData];
          const uniqueLandings = newLandings.filter((landing, index, self) =>
            index === self.findIndex((l) => l.id === landing.id)
          );
          return uniqueLandings;
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  // We fetch available dropdown values for catch registration once on initial load.
  const fetchCatchRegistrationData = async () => {
    try {
      // Run all requests concurrently
      const [
        fishingAreasData,
        storageMethodsData,
        destiniesData,
        fishingStocksData,
        conditionsData,
        combinationData
      ] = await Promise.all([
        getFishingAreas(),
        getStorageMethods(),
        getDestinies(),
        getFishingStocks(),
        getConditions(),
        getCatchCombinations()
      ]);

      // Set the data once all promises resolve
      setFishingAreas(fishingAreasData);
      setStorageMethods(storageMethodsData);
      setDestinies(destiniesData);
      setFishingStocks(fishingStocksData);
      setConditions(conditionsData);
      setCatchCombinations(combinationData);
    } catch (error) {
      logError(error);
    }
  };

  const showSnackbar = (message: string, severity: string) => {
    if (severity === 'success') {
      setSuccessMsg(message);
      setSuccess(true);
    } else if (severity === 'error') {
      setErrorMsg(message);
      setError(true);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(false);
  };

  const handleSelectLanding = (landing: ReWeightingLanding) => {
    setSelectedLanding(landing);
    if (landing.weightNote) {
      setSelectedWeightNote(landing.weightNote);
    }
  };

  return (
    <ReWeightingWrapper>
      <Header user={props.user} isReWeighing={true} selectedPage="Endurvigtun" isAuthorized={true} darkMode={props.darkMode} setDarkMode={props.setDarkMode} />
      <Splitter>
        {(!isMobile && !isTablet || (navigationValue === 0)) &&
          (
            <ReWeightingList
              landings={landings}
              fetchNextLandings={fetchLandings}
              selectedLanding={selectedLanding}
              setSelectedLanding={handleSelectLanding}
              loading={loading}
              width={((isMobile || isTablet) && navigationValue === 0) ? '100%' : '25%'}
            />
          )
        }

        {((!isMobile && !isTablet) || (navigationValue === 1)) &&
          <ConfirmProvider>
            <ReWeightingInfo
              user={props.user}
              refetchLandings={fetchLandings}
              selectedLanding={selectedLanding}
              selectedWeightNote={selectedWeightNote}
              navigationValue={navigationValue}
              loading={loading}
              showSnackbar={showSnackbar}
              catchCombinations={catchCombinations || []}
              fishingAreas={fishingAreas}
              storageMethods={storageMethods}
              destinies={destinies}
              fishingStocks={fishingStocks}
              conditions={conditions}
            />
          </ConfirmProvider>
        }
      </Splitter>

      {(isMobile || isTablet) &&
        <Box>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
              showLabels
              value={navigationValue}
            >
              <BottomNavigationAction label="Endurvigtanir" icon={<ListIcon />} onClick={() => setNavigationValue(0)} />
              <BottomNavigationAction label="Vigtanir" icon={<PollIcon />} onClick={() => setNavigationValue(1)} />
            </BottomNavigation>
          </Paper>
        </Box>
      }
      <SnackBarComponent successMsg={successMsg} isOpen={success} onClose={handleSnackbarClose} autoHideDurationMS={3000} severity={'success'} />
      <SnackBarComponent successMsg={errorMsg} isOpen={error} onClose={handleSnackbarClose} autoHideDurationMS={6000} severity={'error'} />
    </ReWeightingWrapper >
  );
};

export default ReWeighting;